import React from "react";
import "./ContactBlock.scss";
import GatsbyLink from "../../GatsbyLink";
import RenderContent from "../../RenderContent";
import { GravityForm, gravityFormExtractor } from "../../gravity-form";
import { useAllGravityForms } from "../../../hooks";

export const ContactBlock = ({
  theme = "navy",
  contactHeading = "Need expert advice?",
  contactContent = "Fill out the form below and our technical team will get in touch.",
  form = true,
  formId = 1,
  fullWidth = false,
}) => {
  // Get all of our Gravity Forms
  const gravityFormsData = useAllGravityForms();

  const formData = gravityFormExtractor(formId, gravityFormsData);

  const fullWidthClass = fullWidth ? "contact-block--full-width" : "";
  return (
    <section
      className={`contact-block layout contact-block--${theme} ${fullWidthClass}`}
    >
      <div className="contact-block__wrapper">
        {contactHeading && <p className="large-heading">{contactHeading}</p>}
        {contactContent && <RenderContent content={contactContent} />}
        {form ? (
          <GravityForm
            formData={formData}
            displayDescription={true}
            dataLayerEvent={"form_submit_success"}
          />
        ) : (
          <GatsbyLink to="/contact/" className="button-filled">
            <span className="button__text">Contact us</span>
          </GatsbyLink>
        )}
      </div>
    </section>
  );
};
